import DocumentIcon3 from 'components/SVGIcons/DocumentIcon3';
import PdfIcon from 'components/SVGIcons/FileTypes/PdfIcon';
import { IconProps } from 'components/SVGIcons/types';
import { theme } from 'theme/theme';

const iconProps: IconProps = {
    color: theme.colors.primaryColor as string,
    height: 30,
};

const FileIcon = ({ type }: { type: string }) => {
    switch (type) {
        case '.pdf': {
            return <PdfIcon {...iconProps} />;
        }

        default:
            return <DocumentIcon3 {...iconProps} />;
    }
};

export default FileIcon;
