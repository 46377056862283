import { styled } from '@mui/material';
import { transientOptions } from 'helpers/transientOptions';
import { theme } from 'theme/theme';

export const Wrapper = styled('div')(() => ({
    boxShadow: '0px 0px 20px 0px rgba(17, 24, 33, 0.15)',
    borderRadius: '10px',
    overflow: 'hidden',
    width: '100%',
}));

export const DragAndDrop = styled(
    'div',
    transientOptions
)<{ $isDragActive: boolean }>(({ $isDragActive }) => ({
    width: '100%',
    minHeight: '225px',
    display: 'flex',
    padding: '20px 0px',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    gap: '11px',
    borderRadius: '10px',
    border: $isDragActive ? `2px dashed ${theme.colors.primaryColor}` : 'none',
    background: $isDragActive ? theme.colors.primaryColor_4 : 'white',
    boxShadow: '0px 0px 20px 0px rgba(17, 24, 33, 0.15)',
}));

export const Description = styled('div')(({ theme }) => ({
    ...theme.fonts.body_1,
    color: theme.colors.primaryText,
}));

export const HelpText = styled(
    'div',
    transientOptions
)<{ $isError?: boolean }>(({ theme, $isError = false }) => ({
    ...theme.fonts.body_4,
    color: $isError ? theme.colors.errorText : theme.colors.primaryText,
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
}));

export const BrowseButton = styled('button')(({ theme, disabled }) => ({
    ...theme.fonts.button_text,
    padding: '12px 24px',
    background: disabled
        ? theme.colors.secondaryColor_4
        : theme.colors.primaryColor,
    color: theme.colors.white,
    minWidth: '180px',
    border: 'none',
    borderRadius: '20px',
    cursor: disabled ? 'not-allowed' : 'pointer',
}));

export const SelectedFilesWrapper = styled('div')(() => ({}));

export const SelectedFiles = styled('div')(() => ({
    width: '100%',
    borderRadius: '0px 0px 10px 10px',
    maxHeight: '300px',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    padding: '30px 35px 30px 25px',
    top: '10px',
    overflowY: 'scroll',
}));

export const SelectedFile = styled('div')(({ theme }) => ({
    ...theme.fonts.body_2,
    width: '100%',
    borderRadius: '10px',
    background: theme.colors.primaryColor_4,
    display: 'flex',
    position: 'relative',
    padding: '24px',
    gap: '16px',
    color: theme.colors.primaryColor,
    alignItems: 'center',
}));

export const ProgressBar = styled(
    'div',
    transientOptions
)<{ $progress: number }>(({ theme, $progress }) => ({
    height: '4px',
    background: theme.colors.primaryColor_4,
    margin: '5px 35px 10px 25px',
    borderRadius: '10px',
    overflow: 'hidden',
    position: 'relative',

    '&:: after': {
        content: '""',
        height: '100%',
        width: `${$progress}%`,
        background: theme.colors.primaryColor,
        position: 'absolute',
    },
}));
