import { CircularProgress } from '@mui/material';
import { LoaderWrapper } from './Loader.styled';

interface Props {
    height?: string;
    size?: number;
    color?:
        | 'inherit'
        | 'error'
        | 'primary'
        | 'secondary'
        | 'info'
        | 'success'
        | 'warning';
}

const Loader: React.FC<Props> = ({ height = '100vh', size, color }) => {
    return (
        <LoaderWrapper $height={height} data-testid="loader">
            <CircularProgress size={size} color={color ? color : 'primary'} />
        </LoaderWrapper>
    );
};

export default Loader;
