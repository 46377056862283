import {
    CSSProperties,
    ChangeEvent,
    DragEventHandler,
    useRef,
    useState,
} from 'react';
import {
    DragAndDrop,
    BrowseButton,
    Description,
    HelpText,
    SelectedFiles,
    Wrapper,
    SelectedFile,
    ProgressBar,
    SelectedFilesWrapper,
} from './CustomDropZone.styled';
import UploadDocumentIcon from 'components/SVGIcons/UploadDocumentIcon';
import { Controller, useFormContext } from 'react-hook-form';
import { theme } from 'theme/theme';
import CloseIcon from 'components/SVGIcons/CloseIcon';
import { getFileNameAndType } from 'helpers/getFileNameAndType';
import FileIcon from './FileIcon';
import { parseRegExTypes } from 'helpers/parseRegExTypes';

const defaultRegEx = /(\.jpg|\.jpeg|\.png|\.pdf|\.txt|\.docx|\.html)$/i;

const iconColor = theme.colors.secondaryText;

interface Props {
    description?: string;
    formKey: string;
    regularExpression?: RegExp;
    progress?: number;
    multiple?: boolean;
}

const defaultDescription = 'Drag & drop or browse a file';

const deleteIconStyles: CSSProperties = {
    cursor: 'pointer',
    position: 'absolute',
    right: '15px',
    top: '15px',
};

const CustomDropZone: React.FC<Props> = ({
    description = defaultDescription,
    regularExpression = defaultRegEx,
    formKey,
    progress,
    multiple = true,
}) => {
    const {
        control,
        watch,
        formState: { isSubmitting, errors },
    } = useFormContext();
    const [isDragActive, setIsDragActive] = useState(false);
    const [isUnsupportedFile, setIsUnsupportedFile] = useState(false);

    const errorMessage = errors[formKey]?.message;

    const inputRef = useRef<HTMLInputElement | null>(null);

    const selectedFiles = watch(formKey);

    const browseFile = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        if (inputRef.current === null) return;

        inputRef.current.click();
    };

    const handleDrag: DragEventHandler<HTMLElement> = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (e.type === 'dragenter' || e.type === 'dragover') {
            setIsDragActive(true);
        } else if (e.type === 'dragleave') {
            setIsDragActive(false);
        }
    };

    const handleFileSelection = (event: ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        const selectedFiles = event.target.files;

        if (selectedFiles && selectedFiles.length > 0) {
            for (let i = 0; i < selectedFiles.length; i++) {
                const fileName = selectedFiles[i]?.name;

                if (fileName && !regularExpression.test(fileName)) {
                    setIsUnsupportedFile(true);
                    return [];
                }
            }

            const files = [...selectedFiles];
            setIsUnsupportedFile(false);
            return files;
        }

        return [];
    };

    const handleDrop: DragEventHandler<HTMLElement> = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragActive(false);

        const droppedFiles = e.dataTransfer.files;

        if (droppedFiles && droppedFiles.length > 0) {
            for (let i = 0; i < droppedFiles.length; i++) {
                const fileName = droppedFiles[i]?.name;

                if (fileName && !regularExpression.test(fileName)) {
                    setIsUnsupportedFile(true);
                    return [];
                }
            }

            const firstFile = droppedFiles[0];
            const files = multiple ? Array.from(droppedFiles) : [firstFile];
            setIsUnsupportedFile(false);
            return files;
        }
        return [];
    };

    const deleteFileHandler = (index: number) => {
        const updatedFiles = selectedFiles.slice();
        updatedFiles.splice(index, 1);

        setIsUnsupportedFile(false);
        return updatedFiles;
    };

    const allowedExtensions = parseRegExTypes(regularExpression);

    return (
        <Controller
            control={control}
            name={formKey}
            render={({ field: { value, onChange, ...field } }) => {
                return (
                    <Wrapper>
                        <DragAndDrop
                            $isDragActive={isDragActive}
                            onDragEnter={handleDrag}
                            onDragLeave={handleDrag}
                            onDragOver={handleDrag}
                            onDrop={(e) => {
                                const files = handleDrop(e);
                                onChange(files);
                            }}>
                            <UploadDocumentIcon height={60} />

                            <Description>{description}</Description>

                            {errorMessage && (
                                <HelpText $isError={!!errorMessage}>
                                    {errorMessage}
                                </HelpText>
                            )}

                            {isUnsupportedFile && (
                                <HelpText $isError>
                                    Allowed types: {allowedExtensions}
                                </HelpText>
                            )}
                            <input
                                {...field}
                                style={{ display: 'none' }}
                                ref={inputRef}
                                type="file"
                                value={value?.fileName}
                                multiple={multiple}
                                onChange={(e) => {
                                    const values = handleFileSelection(e);
                                    onChange(values);
                                }}
                            />

                            <BrowseButton
                                onClick={browseFile}
                                disabled={isSubmitting}>
                                Browse
                            </BrowseButton>
                        </DragAndDrop>

                        {!!selectedFiles?.length && (
                            <SelectedFilesWrapper>
                                <SelectedFiles>
                                    {selectedFiles.map(
                                        (file: File, index: number) => {
                                            const { name, type } =
                                                getFileNameAndType(file.name);

                                            return (
                                                <SelectedFile key={index}>
                                                    <FileIcon type={type} />

                                                    {name}

                                                    {!progress && (
                                                        <CloseIcon
                                                            width={15}
                                                            height={15}
                                                            color={iconColor}
                                                            style={
                                                                deleteIconStyles
                                                            }
                                                            onClick={() => {
                                                                const files =
                                                                    deleteFileHandler(
                                                                        index
                                                                    );
                                                                onChange(files);
                                                            }}
                                                        />
                                                    )}
                                                </SelectedFile>
                                            );
                                        }
                                    )}
                                </SelectedFiles>

                                {!!progress && (
                                    <ProgressBar $progress={progress} />
                                )}
                            </SelectedFilesWrapper>
                        )}
                    </Wrapper>
                );
            }}
        />
    );
};

export default CustomDropZone;
