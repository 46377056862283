import { styled } from '@mui/material';
import { transientOptions } from 'helpers/transientOptions';

export const LoaderWrapper = styled(
    'div',
    transientOptions
)<{ $height: string }>(({ $height }) => ({
    width: '100%',
    height: $height,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}));
